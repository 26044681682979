<template>
    <section class="body">
        <ExportDialog @ok="selectExportMode" :dialogId="exportDialogId" getter="getProgramsExportQuery" setter="setProgramsExportQuery" />
        <div v-if="programsInitStatus == 'LOADING'">
            <div v-if="loadingFormData" class="text-center mb-2">
                <b-progress :value="loadingFormDataValue" show-progress class="m-5" />
            </div>
        </div>
        <div class="failedRequest" v-if="programsInitStatus == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
        <div v-if="programsInitStatus == 'LOADED'">
            <b-form>
                <b-form-row class="justify-content-center">
                    <DateModalProgram :fieldData="formFields.date" :latestDate="$store.getters.getPublishedToDate" @onInputChanged="inputChanged" />
                    <ProviderPickerProgramsAdsModal :fieldData="formFields.providers" getter="getProgramsQuery" providersSetter="setProgramsQueryProviders" setter="setProgramsQuery" />
                    <TargetGroupPrograms :fieldData="formFields.target_group" getter="getProgramsQuery" setter="setProgramsQuery" />
                    <TitleModal :fieldData="formFields.title" />
                    <GenrePickerModal :fieldData="formFields.genres" getter="getProgramsQuery" setter="setProgramsQuery" />
                    <ToggleButton :fieldData="formFields.show_all_content_with_traffic_in_period" getter="getProgramsQuery" setter="setProgramsQuery" />
                    <CheckboxDropdownColumns :fieldData="columnsField" getter="getProgramsQuery" setter="setProgramsQuery" />
                </b-form-row>
                <b-form-row class="mb-2 justify-content-center">
                    <button v-if="getSelectedInputs > 0" type="button" class="trashbtn mt-2 mr-2" @click="clearAllFields">
                        <b-icon-trash />
                    </button>
                    <div v-for="(selectedButton, key) in selectedBtns" :key="key">
                        <button class="selectedbtns mr-2 mt-2 bubblefontsize" type="button" @click="selectedButton.clickedBtn && selectedButton.clickedBtn()">
                            <span v-html="selectedButton.text" />
                            <b-icon
                                v-if="selectedButton.editable"
                                class="ml-2 crossbtn"
                                font-scale="1.5"
                                icon="x"
                                @click="selectedButton.clickedBtnDelete && selectedButton.clickedBtnDelete($event)"
                            />
                        </button>
                    </div>
                </b-form-row>
                <div v-if="!TargetGroupExists">
                    <div class="text-center mb-2">
                        <div>
                            <small class="text-danger"><strong>Du måste välja målgrupp under Målgrupper / Mått.</strong></small>
                        </div>
                    </div>
                </div>
                <b-form-row class="mb-2 justify-content-center">
                    <div>
                        <b-button :disabled="disableRunBtn" @click="submitSearch" class="searchbutton" variant="primary" size="sm">
                            <font-awesome-icon v-if="status != 'LOADING' && !loadingExportResults" style="font-size: 1rem; position: relative; top: 1px; right: 2px;" :icon="['fas', 'play']" />
                            {{ status === "LOADING" ? "Arbetar..." : "Kör" }}
                        </b-button>
                    </div>
                </b-form-row>
            </b-form>
            <div v-if="exceededConsumptionPeriodLimit && config_ShowConsumtionPeriodProgram">
                <div class="text-center mb-2">
                    <div>
                        <small class="text-danger"><strong>Du får max välja 7 dagar som konsumtionsperiod.</strong></small>
                    </div>
                </div>
            </div>
        </div>
        <hr />

        <b-tabs content-class="mt-3" v-model="tabIndex">
            <b-tab title="Lista" active @click="initListTab">
                <Spinner v-if="status == 'LOADING'" loadingMsg="Laddar resultat..." />
                <div v-else-if="status == 'EMPTY_RESULT'">Hittade inget för aktuell sökning</div>
                <div v-else-if="status == 'LOADED'">
                    <ResultTableMainProgram @onSumButtonClicked="eventTriggerSumTab()" :sortBy.sync="mainSortBy" :sortDesc.sync="mainSortDesc" />
                </div>
                <div class="failedRequest" v-else-if="status == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
                <div v-else-if="status == 'VALIDATION_FAILED'" class="text-danger">
                    <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                        {{ error }}
                    </span>
                </div>
            </b-tab>
            <b-tab title="Summering" @click="fetchProgramsSummary" lazy v-if="config_ShowSummaryProgram" :disabled="status !== 'LOADED' || !this.$store.getters.getProgramsHaveSelectedRow">
                <Spinner v-if="statusSummary == 'LOADING'" loadingMsg="Laddar resultat..." />
                <div v-else-if="statusSummary == 'EMPTY_RESULT'">Hittade inget för aktuell sökning</div>
                <div v-else-if="statusSummary == 'LOADED'">
                    <ResultTableSumProgram :sortBy.sync="sumSortBy" :sortDesc.sync="sumSortDesc" />
                </div>
                <div class="failedRequest" v-else-if="statusSummary == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
                <div v-else-if="statusSummary == 'VALIDATION_FAILED'" class="text-danger">
                    <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                        {{ error }}
                    </span>
                </div>
            </b-tab>
            <template v-slot:tabs-end>
                <b-button
                    :disabled="loadingExportResults || result.data.levelOneProgramsWithLevelTwoPrograms.length === 0"
                    class="ml-auto"
                    size="sm"
                    variant="success"
                    @click="userClickedExport"
                    v-if="status === 'LOADED' && tabName === 'list'"
                >
                    <b-icon v-if="!loadingExportResults" icon="arrow-repeat" />
                    {{ loadingExportResults ? "Arbetar..." : "Exportera" }}
                </b-button>
                <b-button :disabled="loadingExportResults" class="ml-auto" size="sm" variant="success" @click="userClickedExport" v-if="statusSummary == 'LOADED' && tabName === 'sum'">
                    <b-icon v-if="!loadingExportResults" icon="arrow-repeat" />
                    {{ loadingExportResults ? "Arbetar..." : "Exportera" }}
                </b-button>
            </template>
        </b-tabs>
    </section>
</template>

<script>
import DateModalProgram from "./DateModalProgram"
import TitleModal from "./TitleModal"
import Spinner from "@/components/Shared/Spinner/Spinner"
import { baseFields } from "./formData"
import { validationRules } from "./programValidationRules"
import inputValidation from "@/mixins/inputValidation"
import { mapState } from "vuex"
import * as Actions from "@/store/modules/programs/action-types"
import * as Mutations from "@/store/modules/programs/mutation-types"
import * as Helpers from "@/helpers"
import { download, dateToyymmddFormat, scrollToBottom } from "../../../api/utils.js"
import dayjs from "dayjs"
import ResultTableMainProgram from "@/components/Layout/Programs/ResultTableMainProgram"
import ExportDialog from "@/components/Shared/FormElement/Dialogs/ExportDialog"
import globalConfig from "../../../mixins/globalConfig"
import ProviderPickerProgramsAdsModal from "../../Layout/Programs/ProviderPickerProgramsAdsModal.vue"
import GenrePickerModal from "../../Shared/FormElement/GenrePicker/GenrePickerModal.vue"
import ToggleButton from "../../Shared/FormElement/ToggleButton/ToggleButton.vue"
import CheckboxDropdownColumns from "../../Shared/FormElement/CheckboxDropdown/CheckboxDropdownColumns.vue"
import TargetGroupPrograms from "../../Shared/FormElement/TargetGroup/TargetGroupPrograms"

export default {
    name: "Programs",
    components: {
        Spinner,
        ResultTableMainProgram,
        ExportDialog,
        DateModalProgram,
        TitleModal,
        ProviderPickerProgramsAdsModal,
        GenrePickerModal,
        TargetGroupPrograms,
        ToggleButton,
        CheckboxDropdownColumns,
    },
    mixins: [inputValidation, globalConfig],
    data() {
        return {
            loadingFormData: true,
            loadingFormDataValue: 0,
            formFields: baseFields,
            loadingExportResults: false,
            columnsField: {
                config: { options: [] },
                identifier: "columns",
                label: "Kolumner",
            },
            selectAll: false,
            calledGetChannels: false,
            calledGetProviders: false,
            tabName: "list",
            targetLabel: "",
            message: "",
            formErrors: [],
            tabIndex: 0,
            contentKeys: {
                programs: "",
                showConsumtionPeriodProgram: "",
            },
            exportDialogId: "programs-export-modal",
            mainSortBy: "measure_1",
            mainSortDesc: true,
            sumSortBy: "measure_1",
            sumSortDesc: true,
            selectedBtns: [],
        }
    },
    beforeDestroy() {
        document.removeEventListener("keyup", this.onKeyUp)
    },
    watch: {
        "$store.getters.getPublishedToDate"(publishedToDate) {
            this.setStartAndEndDate(publishedToDate)
        },
        "$store.getters.getProgramsQuery.start_date"() {
            let endDate = this.$store.getters.getProgramsQuery.end_date
            let startDate = this.$store.getters.getProgramsQuery.start_date

            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate
            if (!validEndDate || !validStartDate) {
                return
            }

            if (startDate > endDate) {
                this.$store.commit(Mutations.setProgramsEnddate, startDate)
                return
            }
        },
        "$store.getters.getProgramsQuery.end_date"() {
            let endDate = this.$store.getters.getProgramsQuery.end_date
            let startDate = this.$store.getters.getProgramsQuery.start_date

            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate

            if (!validEndDate || !validStartDate) {
                return
            }

            if (startDate > endDate && endDate) {
                this.$store.commit(Mutations.setProgramsStartdate, endDate)
                return
            }
        },
        "$store.getters.getProgramsQuery": {
            deep: true,
            handler(query) {
                this.resetFailedOrEmptyState()
                let selectedBtns = []
                selectedBtns.push({
                    text: `<span class="font-weight-bold">Konsumtionsdatum:</span> ${query.start_date} - ${query.end_date}`,
                    editable: false,
                    clickedBtn: () => {
                        this.formFields.date.showDateModal = true
                    },
                })
                if (query.searchForPubDate)
                    selectedBtns.push({
                        text: `<span class="font-weight-bold">Publiceringsdatum:</span> ${query.pub_start_date} - ${query.pub_end_date}`,
                        editable: true,
                        clickedBtn: () => {
                            this.formFields.date.showDateModal = true
                        },
                        clickedBtnDelete: event => {
                            event.stopPropagation()
                            this.query.searchForPubDate = false
                            this.query.pub_start_date = ""
                            this.query.pub_end_date = ""
                        },
                    })
                if (query.provider_keys.length > 0) {
                    selectedBtns.push({
                        text: `<span class="font-weight-bold">Kanaler:</span> ${query.provider_keys.length} valda`,
                        editable: true,
                        clickedBtn: () => {
                            this.formFields.providers.showChannelModal = true
                        },
                        clickedBtnDelete: event => {
                            event.stopPropagation()
                            this.query.provider_keys = []
                        },
                    })
                }
                let numberOfStoredTargets = this.getTargetGroupCount()
                if (numberOfStoredTargets > 0) {
                    let chosenText = numberOfStoredTargets > 1 ? "valda" : "vald"
                    selectedBtns.push({
                        text: `<span class="font-weight-bold"> Målgrupp / Mått </span> ${numberOfStoredTargets} ${chosenText}`,
                        editable: true,
                        clickedBtn: () => {
                            this.formFields.target_group.showTargetGroupModal = true
                        },
                        clickedBtnDelete: event => {
                            event.stopPropagation()
                            this.removeTargets()
                        },
                    })
                }
                if (query.title) {
                    let titleText = `<span class="font-weight-bold">Titel:</span> ${query.title}`
                    if (titleText.length > 70) titleText = titleText.substring(0, 70) + "..."
                    selectedBtns.push({
                        text: titleText,
                        editable: true,
                        clickedBtn: () => {
                            this.formFields.title.showTitleModal = true
                        },
                        clickedBtnDelete: event => {
                            event.stopPropagation()
                            let updatedQuery = {
                                ...this.$store.getters["getProgramsQuery"],
                                title: "",
                            }
                            this.$store.commit("setProgramsQuery", updatedQuery)
                        },
                    })
                }
                let numberOfStoredGenres = this.getNumberOfStoredGenres()
                if (numberOfStoredGenres > 0) {
                    let chosenText = numberOfStoredGenres > 1 ? "valda" : "vald"
                    selectedBtns.push({
                        text: `<span class="font-weight-bold">Genre:</span> ${numberOfStoredGenres} ${chosenText}`,
                        editable: true,
                        clickedBtn: () => {
                            this.formFields.genres.showGenreModal = true
                        },
                        clickedBtnDelete: event => {
                            event.stopPropagation()
                            this.removeGenres()
                        },
                    })
                }
                let emptyAnswer = query.show_all_content_with_traffic_in_period
                let yesOrNo = emptyAnswer ? "ja" : "nej"
                selectedBtns.push({
                    text: `<span class="font-weight-bold">Visa tomma svar:</span> ${yesOrNo}`,
                    editable: false,
                    clickedBtn: () => {
                        this.query.show_all_content_with_traffic_in_period = !this.query.show_all_content_with_traffic_in_period
                    },
                })
                this.selectedBtns = selectedBtns
            },
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        this.init()
        let customParseFormat = require("dayjs/plugin/customParseFormat")
        dayjs.extend(customParseFormat)
    },

    computed: {
        allModalsClosed() {
            let allModalsClosed = false
            if (
                !this.formFields.date.showDateModal &&
                !this.formFields.genres.showGenreModal &&
                !this.formFields.providers.showChannelModal &&
                !this.formFields.target_group.showTargetGroupModal &&
                !this.formFields.title.showTitleModal
            )
                allModalsClosed = true
            return allModalsClosed
        },
        getSelectedInputs() {
            let selectedInputs = this.selectedBtns.length
            return selectedInputs
        },
        disableRunBtn() {
            let disableRunBtn = false
            if (this.status == "LOADING" || this.$store.getters.getProgramsQuery.columns.length == 0 || !this.TargetGroupExists || this.exceededConsumptionPeriodLimit) disableRunBtn = true
            return disableRunBtn
        },
        programsExportQuery() {
            return this.$store.getters.getProgramsExportQuery
        },
        result() {
            return this.$store.getters.getProgramsResults
        },
        selectedResult() {
            return this.$store.getters.getProgramsSelectedResults
        },
        status() {
            return this.$store.getters.getProgramsResults.status
        },
        statusSummary() {
            return this.$store.getters.getProgramsSummaryResults.status
        },
        // lite state från vuex
        ...mapState({
            programsInitStatus: ({ programs }) => programs.programsInitStatus,
            query: ({ programs }) => programs.programsQuery,
        }),
        TargetGroupExists() {
            this.$store.getters.getProgramsQuery.target_group

            let storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            if (storedTargets) {
                if (typeof storedTargets.getProgramsQuery === "undefined") storedTargets = null
            }

            return storedTargets != null && storedTargets.getProgramsQuery.length > 0 ? true : false
        },
        exceededConsumptionPeriodLimit() {
            const maxMmsDays = 7
            let startDate = this.$store.getters.getProgramsQuery.start_date
            let endDate = dayjs(this.$store.getters.getProgramsQuery.end_date)
            let chosenMmsDays = dayjs(endDate).diff(startDate, "day") + 1
            return chosenMmsDays > maxMmsDays
        },
    },
    methods: {
        onKeyUp(event) {
            if (event.key === "Enter" && this.allModalsClosed) {
                this.onEnter()
            }
        },
        removeGenres() {
            let emptyGenres = {
                genres: [],
                genre_keys: [],
            }
            const updatedQuery = {
                ...this.$store.getters.getProgramsQuery,
                genres: { ...emptyGenres },
            }
            this.$store.commit("setProgramsQuery", updatedQuery)
            let storedGenres = localStorage.getItem("genres") ? JSON.parse(localStorage.getItem("genres")) : {}
            storedGenres["getProgramsQuery"] = { ...emptyGenres }
            localStorage.setItem("genres", JSON.stringify(storedGenres))
        },
        removeTargets() {
            const updatedQuery = {
                ...this.$store.getters.getProgramsQuery,
                target_group: [],
            }
            this.$store.commit("setProgramsQuery", updatedQuery)
            var storedTargetGroups = JSON.parse(localStorage.getItem("targetGroups"))
            if (storedTargetGroups != null) {
                storedTargetGroups.getProgramsQuery = []
                localStorage.setItem("targetGroups", JSON.stringify(storedTargetGroups))
            }
        },
        getNumberOfStoredGenres() {
            let storedGenres = localStorage.getItem("genres") ? JSON.parse(localStorage.getItem("genres")) : []
            if (storedGenres) {
                if (typeof storedGenres["getProgramsQuery"] === "undefined") storedGenres = null
            }
            let numberOfStoredGenres = storedGenres != null ? storedGenres["getProgramsQuery"].genres.length : 0
            return numberOfStoredGenres
        },
        getTargetGroupCount() {
            let storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            if (storedTargets) {
                if (typeof storedTargets["getProgramsQuery"] === "undefined") storedTargets = null
            }

            return storedTargets != null ? storedTargets["getProgramsQuery"].length : 0
        },
        eventTriggerSumTab() {
            this.tabIndex = 1
            this.fetchProgramsSummary()
        },
        async fetchProgramsSummary() {
            this.tabName = "sum"
            this.sumSortBy = "measure_1"
            this.sumSortDesc = true
            this.contentKeys.showConsumtionPeriodProgram = this.config_ShowConsumtionPeriodProgram
            this.contentKeys.programs = this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms
                .map(p => p.levelTwoPrograms)
                .flat()
                .filter(p => p.chosen)
                .map(p => p.contentKey)
            await this.$store.dispatch(Actions.executeProgramsSummaryQuery, this.contentKeys)
        },
        addTdClassToLastSumColumns(columns) {
            let passedColumnBeforeMeasure = false
            let indexOfColumnBeforeMeasure = 0
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].label == this.$store.getters.getProgramsResults.data.summary.columnBeforeMeasures || passedColumnBeforeMeasure) {
                    columns[i].tdClass = "tdSummaryDataClass"
                    if (!passedColumnBeforeMeasure) indexOfColumnBeforeMeasure = i
                    passedColumnBeforeMeasure = true
                }
            }
            if (passedColumnBeforeMeasure && indexOfColumnBeforeMeasure > 0) columns[indexOfColumnBeforeMeasure - 1].tdClass = "columnBeforeSummaryData"
            return columns
        },
        clearAllFields() {
            this.$store.commit(Mutations.setProgramsSearchForPubDate, false)
            //Remove genres from local storage
            var storedGenres = JSON.parse(localStorage.getItem("genres"))
            if (storedGenres != null) {
                storedGenres.getProgramsQuery = { genres: [], genre_keys: [] }
                localStorage.setItem("genres", JSON.stringify(storedGenres))
            }
            //Remove targetgroups from local storage
            var storedTargetGroups = JSON.parse(localStorage.getItem("targetGroups"))
            if (storedTargetGroups != null) {
                storedTargetGroups.getProgramsQuery = []
                localStorage.setItem("targetGroups", JSON.stringify(storedTargetGroups))
            }
            let systemToDate = new Date(this.$store.getters.getMetadataProgramToDate)
            this.$store.commit(Mutations.setProgramsQuery, {
                ...this.$store.getters.getProgramsQuery,
                provider_keys: [],
                channels: [],
                columns: this.$store.getters.getProgramsDefaultColumns,
                title: "",
                end_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate())),
                start_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate() - 1)),
                pub_end_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate())),
                pub_start_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate() - 1)),
                with_wildcards: true,
                weekdays: [],
                genres: { genres: [], genre_keys: [] },
                target_group: [],
                show_all_content_with_traffic_in_period: false,
            })
        },
        removeKeyColumn(columnArray) {
            return columnArray.filter(x => !(x.column_type == this.keyColumn.column_type && x.key == this.keyColumn.display_name))
        },
        userClickedExport() {
            //bring up modal
            this.$bvModal.show(this.exportDialogId)
        },
        selectExportMode() {
            //redirect to regular or summary export
            if (this.tabName === "list") {
                this.exportResult()
            } else {
                this.exportSumResult()
            }
        },
        async exportResult() {
            this.loadingExportResults = true
            this.contentKeys.showConsumtionPeriodProgram = this.config_ShowConsumtionPeriodProgram
            this.contentKeys.programs = this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms
                .map(p => p.levelTwoPrograms)
                .flat()
                .map(p => p.contentKey)
            let exportToCsv
            try {
                let query = {
                    ...this.$store.getters.getProgramsQueryForAPI,
                    sortBy: this.mainSortBy,
                    sortByDesc: this.mainSortDesc,
                }
                await this.$store.commit(Mutations.setProgramsQueryForAPI, query)
                exportToCsv = await this.$store.dispatch(Actions.executeExportProgramsQuery, this.contentKeys)
            } catch (error) {
                this.showMsg("Serverfel", "Det gick inte att exportera program", "danger")
                this.loadingExportResults = false
                return
            }
            let fileName = ""
            let fileSuffix = this.programsExportQuery.includeDetailRows ? ".csv" : "_Exklusive_Detaljrader.csv"
            if (this.$store.getters.getExcelExportFilePreName == undefined || this.$store.getters.getExcelExportFilePreName == "") {
                fileName = `TotalanalysProgram_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            } else {
                fileName = `${this.$store.getters.getExcelExportFilePreName} TotalanalysProgram_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            }
            download(exportToCsv, fileName, null)
            this.loadingExportResults = false
        },
        async exportSumResult() {
            this.loadingExportResults = true
            let exportToCsv
            try {
                let query = {
                    ...this.$store.getters.getProgramsQueryForAPI,
                    sortBy: this.sumSortBy,
                    sortByDesc: this.sumSortDesc,
                }
                await this.$store.commit(Mutations.setProgramsQueryForAPI, query)
                exportToCsv = await this.$store.dispatch(Actions.executeExportProgramsSummaryQuery, this.contentKeys)
            } catch (error) {
                this.showMsg("Serverfel", "Det gick inte att exportera summering av program", "danger")
                this.loadingExportResults = false
                return
            }
            let fileName = ""
            let fileSuffix = this.programsExportQuery.includeDetailRows ? ".csv" : "_Exklusive_Detaljrader.csv"
            if (this.$store.getters.getExcelExportFilePreName == undefined || this.$store.getters.getExcelExportFilePreName == "") {
                fileName = `TotalanalysProgramSummering_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            } else {
                fileName = `${this.$store.getters.getExcelExportFilePreName} TotalanalysProgramSummering_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            }
            download(exportToCsv, fileName, null)
            this.loadingExportResults = false
        },
        showMsg(title, msg, variant) {
            this.$bvToast.toast(msg, {
                title: title,
                variant: variant,
                solid: true,
                autoHideDelay: 5000,
            })
        },
        getPanelDay(startDate, endDate) {
            let panelDay = new Date((startDate.getTime() + endDate.getTime()) / 2)
            let difference = endDate.getTime() - startDate.getTime()
            let days = Math.ceil(difference / (1000 * 3600 * 24)) + 1
            let isOdd = days % 2
            if (isOdd == 0) {
                panelDay.setDate(panelDay.getDate() + 1)
                return panelDay
            }
            return panelDay
        },
        async init() {
            // många anrop med sideeffects, kör igång med actions
            try {
                await this.$store.commit(Mutations.setProgramsInitStatus, "LOADING")
                const genres = await this.$store.dispatch(Actions.getProgramsGenres)
                this.loadingFormDataValue = 25
                this.setStartAndEndDate(this.$store.getters.getPublishedToDate)
                this.$store.commit("setProgramsSelectedResults", [])
                await this.getProviders()
                this.loadingFormDataValue = 50
                await this.$store.commit(Mutations.setProgramsGenres, genres)
                // Measures:
                const measures = await this.$store.dispatch(Actions.getProgramsMeasures)
                this.loadingFormDataValue = 75
                this.formFields.target_group.config.measures = [...measures]
                // GENRES:
                const newGenres = Helpers.transformToGenresFromAPIResponse(genres)
                // COLUMNTYPES:
                const columns = await this.$store.dispatch(Actions.getProgramsColumnTypes)
                this.loadingFormDataValue = 100
                this.columnsField.config.options = [...columns]
                await this.$store.commit(Mutations.setProgramsColumnTypes, columns)
                await this.$store.commit(
                    Mutations.setProgramsDefaultColumns,
                    columns.filter(c => c.default_selection).map(c => c.value)
                )
                let updatedQuery = {
                    ...this.$store.getters["getProgramsQuery"],
                    ["columns"]: this.$store.getters.getProgramsDefaultColumns,
                }
                // copy store to local state --
                const newFields = {
                    ...this.formFields,
                    genres: { ...this.formFields.genres, ...newGenres },
                }
                this.$store.commit("setProgramsQuery", updatedQuery)
                // viktigt med await för att vue ska uppdatera våra komponenter
                // status måste vara = LOADED för att de ska visas och de refreshas då när vi sätter formFields,
                // om de inte visas så refreshas de icke...
                await this.$store.commit(Mutations.setProgramsInitStatus, "LOADED")
                document.addEventListener("keyup", this.onKeyUp)
                this.formFields = newFields
            } catch (error) {
                await this.$store.commit(Mutations.setProgramsInitStatus, "FAILED")
            }
        },
        async submitSearch() {
            try {
                this.tabIndex = 0
                this.selectAll = false
                if (!this.TargetGroupExists || this.exceededConsumptionPeriodLimit) return
                validationRules(this)
                const hasError = this.validateForm()
                if (hasError) {
                    scrollToBottom()
                    if (this.formFields.start_date.hasError || this.formFields.end_date.hasError) this.formErrors.push("Vänligen fyll i konsumtionsperiod från och konsumtionsperiod till korrekt.")
                    this.$store.commit(Mutations.setProgramsResults, {
                        status: "VALIDATION_FAILED",
                    })
                    return
                }
                this.$store.commit("setProgramsHaveSelectedRow", false)
                this.tabName = "list"
                //reset the sorting in the main table
                let query = {
                    ...this.$store.getters.getProgramsQueryForAPI,
                    sortBy: "measure_1",
                    sortByDesc: true,
                }
                this.$store.commit(Mutations.setProgramsQueryForAPI, query)
                this.mainSortBy = "measure_1"
                this.mainSortDesc = true
                this.sumSortBy = "measure_1"
                this.sumSortDesc = true
                await this.$store.dispatch(Actions.executeProgramsQuery, this.config_ShowConsumtionPeriodProgram)
            } catch {
                this.$store.commit(Mutations.setProgramsResults, {
                    status: "FAILED",
                })
            }
        },
        selectRow(items) {
            return this.$store.commit("setProgramsSelectedResults", items)
        },
        setStartAndEndDate(publishedToDate) {
            if (!publishedToDate) return
            let previousDate = this.$store.getters.getProgramsQuery
            //only set endate to defaultdate when endate is not changed
            if (!previousDate.end_date) this.$store.commit(Mutations.setProgramsEnddate, this.$store.getters.getMetadataProgramToDate)

            if (!previousDate.start_date) {
                this.$store.commit(Mutations.setProgramsStartdate, this.$store.getters.getMetadataProgramToDate)
                this.$store.commit(Mutations.setProgramsPubStartdate, this.$store.getters.getMetadataProgramToDate)
            }
        },

        async getProviders() {
            this.calledGetProviders = true
            const providerObjects = await this.$store.dispatch(Actions.getProgramsProviders)
            const providers = Object.keys(providerObjects).map(k => providerObjects[k])
            await this.$store.commit(Mutations.setProgramsProviders, providers)

            const sites = providers.filter(x => x.provider_type == "SITE" && x.exists_content)
            const channels = providers
                .filter(x => x.provider_type == "CHANNEL" && x.exists_content)
                .reduce((p, c) => {
                    return { ...p, [c.provider_group]: [...(p[c.provider_group] ?? []), c] }
                }, {})

            const newFields = {
                ...this.formFields,
                providers: { ...this.formFields.providers, sites: sites, channels: channels },
            }

            this.formFields = newFields
            this.calledGetProviders = false
        },
        onEnter() {
            this.submitSearch()
        },
        initListTab() {
            this.tabName = "list"
        },
        resetFailedOrEmptyState() {
            if (this.$store.getters.getProgramsResults.status == "FAILED" || this.$store.getters.getProgramsResults.status == "EMPTY_RESULT") {
                const updatedQuery = {
                    ...this.$store.getters.getProgramsResults,
                    status: "NOT_LOADED",
                }
                this.$store.commit(Mutations.setProgramsResults, updatedQuery)
            } else if (this.$store.getters.getProgramsSummaryResults.status == "FAILED" || this.$store.getters.getProgramsSummaryResults.status == "EMPTY_RESULT") {
                const updatedQuery = {
                    ...this.$store.getters.getProgramsSummaryResults,
                    status: "NOT_LOADED",
                }
                this.$store.commit(Mutations.setProgramsSummaryResults, updatedQuery)
            }
        },
    },
}
</script>

<style>
.body {
    background-color: white;
    padding: 20px 30px;
}

.totalRowsStyle {
    margin-left: 10px;
    margin-top: 8px;
}

.searchbutton {
    width: 8vw;
}

.label-text {
    font-weight: 713;
    font-size: 11px;
    color: #495057;
    float: left;
    margin-bottom: 0.35rem;
}

.nav-tabs {
    font-size: 80%;
    font-weight: 400;
    align-items: center;
}

.measure-header {
    text-align: right;
    line-height: 1.1;
}

.failedRequest {
    color: red;
}
</style>
