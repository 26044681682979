<template>
    <div class="mr-3">
        <button type="button" :class="['btn btnclass smallerfontsize', addSelectedClass]" @click="fieldData.showGenreModal = true">
            Genre
        </button>
        <b-modal v-model="fieldData.showGenreModal" @ok="saveGenres" @show="showModal" @hide="onHidden" :no-close-on-backdrop="true" size="lg" title="Välj Genre">
            <b-tabs content-class="px-2">
                <b-tab title="Genre">
                    <div class="form-row">
                        <div class="col-sm-4">
                            <b-form-select class="my-4" size="sm" :options="genre1Options" v-model="genre1"></b-form-select>
                        </div>
                        <div class="col-sm-4">
                            <b-form-select class="my-4" size="sm" :options="genre2Options" v-model="genre2" :disabled="!genre2Options.length"></b-form-select>
                        </div>
                        <div class="col-sm-4">
                            <b-form-select class="my-4" size="sm" :options="genre3Options" v-model="genre3" :disabled="!genre3Options.length"></b-form-select>
                        </div>

                        <div class="col-sm-12">
                            <b-button variant="success btn-sm" class="my-4" @click="addGenre" :disabled="!genre1">
                                Lägg till
                            </b-button>
                            <b-button @click="removeGenres" v-if="genres.length > 1" class="my-4 ml-2" variant="danger" size="sm">Ta bort alla</b-button>
                        </div>
                        <b-list-group class="col-sm-12">
                            <b-list-group-item v-bind:key="index" v-for="(item, index) in genres">
                                <span>{{ stringifyGenre(item) }}</span>
                                <b-button v-on:click="removeGenre(index)" class="ml-2 float-right" variant="danger" size="sm">Ta bort </b-button>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </b-tab>
            </b-tabs>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import props from "@/mixins/inputProps"
export default {
    name: "GenrePickerModal",
    mixins: [props],
    mounted() {
        this.setGenresFromLocalStorage()
    },
    data() {
        return {
            genre1: null,
            genre2: null,
            genre3: null,
        }
    },
    watch: {
        genre1: function() {
            this.genre2 = null
        },
        genre2: function() {
            this.genre3 = null
        },
    },
    computed: {
        genre1Options() {
            return [{ value: null, text: "Välj ett alternativ" }, ...new Set(this.$props.fieldData.config.genres.map(g => g.genre1))]
        },
        genre2Options() {
            let genre2Options = [...new Set(this.$props.fieldData.config.genres.filter(g => g.genre1 == this.genre1 && g.genre2 !== "-").map(g => g.genre2))]
            if (genre2Options.length > 0) genre2Options.unshift({ value: null, text: "Välj ett alternativ" })
            return genre2Options
        },
        genre3Options() {
            let genre3Options = [...new Set(this.$props.fieldData.config.genres.filter(g => g.genre1 == this.genre1 && g.genre2 == this.genre2 && g.genre3 !== "-").map(g => g.genre3))]
            if (genre3Options.length > 0) genre3Options.unshift({ value: null, text: "Välj ett alternativ" })
            return genre3Options
        },

        genres() {
            let storedGenres = this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier].genres : []
            return storedGenres
        },
        addSelectedClass() {
            this.$store.getters[this.getter]?.[this.fieldData.identifier]
            let adThisClass = ""
            if (this.numberOfStoredGenres() > 0) adThisClass = "selected"
            return adThisClass
        },
    },
    methods: {
        onHidden() {
            document.removeEventListener("keyup", this.onKeyUp)
        },
        numberOfStoredGenres() {
            let storedGenres = localStorage.getItem("genres") ? JSON.parse(localStorage.getItem("genres")) : []
            if (storedGenres) {
                if (typeof storedGenres[this.getter] === "undefined") storedGenres = null
            }
            return storedGenres != null ? storedGenres[this.getter].genres.length : 0
        },
        showModal() {
            document.addEventListener("keyup", this.onKeyUp)
            this.genre1 = null
            this.setGenresFromLocalStorage()
        },
        onKeyUp(event) {
            if (event.key === "Enter" && this.fieldData.showGenreModal) {
                this.saveGenres()
                this.fieldData.showGenreModal = false
            }
        },
        setGenresFromLocalStorage() {
            let genresInTab = JSON.parse(localStorage.getItem("genres"))
            if (genresInTab) {
                if (typeof genresInTab[this.getter] === "undefined") genresInTab = null
            }
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: genresInTab ? genresInTab[this.getter] : { genres: [], genre_keys: [] },
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
        setLocalStorage(query) {
            return localStorage.setItem("genres", JSON.stringify(query))
        },
        addGenre() {
            if (!this.genre1) return

            const config = {
                genre1: this.genre1,
                genre2: this.genre2,
                genre3: this.genre3,
            }

            if (this.genres.filter(g => g.genre1 === config.genre1 && g.genre2 === config.genre2 && g.genre3 === config.genre3).length !== 0) {
                this.$bvToast.toast("Genren finns redan", {
                    title: "Varning",
                    variant: "warning",
                    solid: true,
                    autoHideDelay: 3000,
                })
            } else {
                let newGenreKeys = [...this.$store.getters[this.getter].genres.genre_keys, ...this.getGenreKeysFromGenre(config)]

                const updatedGenres = [...this.genres]
                updatedGenres.push(config)

                const updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: { genres: updatedGenres, genre_keys: newGenreKeys },
                }

                return this.$store.commit(this.setter, updatedQuery)
            }
        },
        removeGenre(index) {
            const updatedGenres = [...this.genres]
            updatedGenres.splice(index, 1)
            let removeTheseGenreKeys = this.getGenreKeysFromGenre(this.genres[index])

            let updatedGenreKeys = this.$store.getters[this.getter].genres.genre_keys.filter(function(genreKey) {
                if (!removeTheseGenreKeys.includes(genreKey)) return genreKey
            })

            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: { genres: updatedGenres, genre_keys: updatedGenreKeys },
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
        removeGenres() {
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: { genres: [], genre_keys: [] },
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
        stringifyGenre(genre) {
            return [genre.genre1, genre.genre2, genre.genre3].filter(x => x).join("; ")
        },
        saveGenres() {
            const updatedGenres = localStorage.getItem("genres") ? JSON.parse(localStorage.getItem("genres")) : {}
            updatedGenres[this.getter] = {
                genres: [...this.genres],
                genre_keys: this.$store.getters[this.getter].genres.genre_keys,
            }
            this.setLocalStorage(updatedGenres)
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedGenres[this.getter],
            }
            this.$store.commit(this.setter, updatedQuery)
        },
        getGenreKeysFromGenre(genre) {
            let newGenre_keys = []
            if (genre.genre1 && genre.genre2 && genre.genre3) {
                newGenre_keys = [...newGenre_keys, ...this.fieldData.config.genres.filter(g => g.genre1 == genre.genre1 && g.genre2 == genre.genre2 && g.genre3 == genre.genre3).map(g => g.genre_key)]
            } else if (genre.genre1 && genre.genre2 && !genre.genre3) {
                newGenre_keys = [...newGenre_keys, ...this.fieldData.config.genres.filter(g => g.genre1 == genre.genre1 && g.genre2 == genre.genre2).map(g => g.genre_key)]
            } else if (genre.genre1 && !genre.genre2 && !genre.genre3) {
                newGenre_keys = [...newGenre_keys, ...this.fieldData.config.genres.filter(g => g.genre1 == genre.genre1).map(g => g.genre_key)]
            }
            return newGenre_keys
        },
    },
}
</script>

<style scoped>
label {
    width: 100%;
}

.selectedItemsClass {
    background-color: #e5f3ff;
    border-color: #337ab7;
    color: #6c757d;
}
</style>
