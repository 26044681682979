<template>
    <div class="mr-3">
        <button type="button" :class="['btn btnclass smallerfontsize', addSelectedClass]" @click="fieldData.showTitleModal = true">
            Titel
        </button>
        <b-modal v-model="fieldData.showTitleModal" :no-close-on-backdrop="true" size="md" title="Titel" @shown="popupIsShown" @hide="popupIsHidden" @ok="saveTitle">
            <div class="wcDiv">
                <label class="wlabel">
                    Wildcardstöd
                    <b-form-checkbox id="with_wildcards" v-model="isWildcardSearch" size="sm" type="checkbox" class="wCheckbox"
                /></label>
            </div>
            <b-form-input v-model="title_search" placeholder="titel" size="sm" autofocus />
            <div class="explanation mt-2">
                Anges i något av följande exempelformat:
            </div>
            <div class="explanation">
                Sportnytt, Sport*, *nytt, *ortny*, eller via Titelkod
            </div>
            <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import * as Mutations from "@/store/modules/programs/mutation-types"

export default {
    name: "TitleModal",
    props: {
        fieldData: Object,
    },
    data() {
        return {
            title_search: "",
            isWildcardSearch: true,
        }
    },
    computed: {
        addSelectedClass() {
            let selectedClass = ""
            const query = this.$store.getters.getProgramsQuery
            if (query.title) selectedClass = "selected"
            return selectedClass
        },
    },
    methods: {
        popupIsShown() {
            document.addEventListener("keyup", this.onKeyUp)
            const query = this.$store.getters.getProgramsQuery
            this.isWildcardSearch = query.with_wildcards
            this.title_search = query.title
        },
        onKeyUp(event) {
            if (event.key === "Enter" && this.fieldData.showTitleModal) {
                this.saveTitle()
                this.fieldData.showTitleModal = false
            }
        },
        popupIsHidden() {
            document.removeEventListener("keyup", this.onKeyUp)
        },
        saveTitle() {
            const updatedQuery = {
                ...this.$store.getters.getProgramsQuery,
                title: this.title_search,
                with_wildcards: this.isWildcardSearch,
            }
            this.$store.commit(Mutations.setProgramsQuery, updatedQuery)
        },
    },
}
</script>

<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}

.wcDiv {
    float: right;
    margin-top: 8px;
    height: 13px;
}

.wlabel {
    float: right;
    margin: 0px;
    font-weight: normal;
    font-size: xx-small;
}
.wInfo {
    transform: scale(1.4);
    margin-left: 2px;
}
.wCheckbox {
    float: right;
    margin-top: -5px;
    margin-left: 5px;
    transform: scale(0.9);
}
</style>
