<template>
    <div>
        <button type="button" class="btn btnclass smallerfontsize mr-3 selected" @click="fieldData.showDateModal = true">
            Datum
        </button>
        <b-modal id="modal-date" v-model="fieldData.showDateModal" title="Datum" @shown="popupIsShown" @ok="saveDate">
            <b-form-row>
                <b-col lg="6" class="pl-2">
                    <label :class="['label-text', fieldData.start_date.hasError ? 'error' : '']">
                        {{ fieldData.start_date.label }}
                    </label>
                    <div :class="['wrapsmallbtns date-presets-mini-buttons', hideSmallDateButtons ? 'visibility-hidden' : '']">
                        <b-button @click="clicked1d" v-b-tooltip.hover title="1d: Sätt start- och slutdatum till senaste dagen med data" variant="primary" class="datefrombtn">1d</b-button>
                        <b-button @click="clicked1v" v-b-tooltip.hover title="1v: Sätt startdatum till en vecka från senaste dagen med data" variant="primary" class="datefrombtn">1v</b-button>
                        <b-button @click="clickedF1v" v-b-tooltip.hover title="f1v: Sätt start- och slutdatum till den senaste kompletta veckan med data" variant="primary" class="datefrombtn"
                            >f1v</b-button
                        >
                    </div>
                    <DateFromPrograms
                        :fieldData="fieldData.start_date"
                        :latestDate="$store.getters.getPublishedToDate"
                        @onInputChanged="inputChanged"
                        getter="getProgramsQuery"
                        setter="setProgramsQuery"
                        providersSetter="setProgramsQueryProviders"
                        parentName="programs"
                        mutationSetEnddate="setProgramsEnddate"
                        mutationSetStartdate="setProgramsStartdate"
                    />
                </b-col>
                <b-col lg="6" class="pr-2">
                    <DateToPrograms
                        v-if="config_ShowConsumtionPeriodProgram"
                        :fieldData="fieldData.end_date"
                        :latestDate="$store.getters.getPublishedToDate"
                        getter="getProgramsQuery"
                        setter="setProgramsQuery"
                        providersSetter="setProgramsQueryProviders"
                        parentName="programs"
                        mutationSetEnddate="setProgramsEnddate"
                        mutationSetStartdate="setProgramsStartdate"
                    />
                </b-col>
            </b-form-row>
            <b-form-row>
                <PublishDate
                    :fieldData="fieldData.pub_date"
                    :latestDate="$store.getters.getPublishedToDate"
                    getter="getProgramsQuery"
                    setter="setProgramsQuery"
                    providersSetter="setProgramsQueryProviders"
                    parentName="programs"
                    mutationSetEnddate="setProgramsEnddate"
                    mutationSetStartdate="setProgramsStartdate"
                />
            </b-form-row>
            <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" :disabled="disableRunBtn()" @click="ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DateFromPrograms from "@/components/Shared/FormElement/DatePicker/DateFromPrograms"
import DateToPrograms from "@/components/Shared/FormElement/DatePicker/DateToPrograms"
import PublishDate from "@/components/Shared/FormElement/DatePicker/PublishDate"
import dayjs from "dayjs"
import propsDate from "@/mixins/inputPropsDate"

export default {
    name: "DateModalProgram",
    mixins: [propsDate],
    computed: {
        hideSmallDateButtons() {
            return !this.config_ShowConsumtionPeriodProgram
        },
    },
    components: {
        DateFromPrograms,
        DateToPrograms,
        PublishDate,
    },
    props: {
        fieldData: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    watch: {
        async "fieldData.start_date.tempStartDate"() {
            let startDate = this.fieldData.start_date.tempStartDate
            let endDate = this.fieldData.end_date.tempEndDate
            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate) {
                this.fieldData.end_date.tempEndDate = startDate
            }
        },
        async "fieldData.end_date.tempEndDate"() {
            let startDate = this.fieldData.start_date.tempStartDate
            let endDate = this.fieldData.end_date.tempEndDate

            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate && endDate) {
                this.fieldData.start_date.tempStartDate = endDate
            }
        },
    },
    methods: {
        disableRunBtn() {
            let disableRunBtn = false
            if (this.fieldData.start_date.hasError || this.fieldData.end_date.hasError || this.fieldData.pub_date.hasError) disableRunBtn = true
            return disableRunBtn
        },
        inputChanged(hasError, identifier) {
            this.$emit("onInputChanged", hasError, identifier)
        },
        isValidDate(date) {
            return dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD") === date
        },
        clicked1d() {
            this.fieldData.start_date.tempStartDate = this.latestDate
            this.fieldData.end_date.tempEndDate = this.latestDate
        },
        clicked1v() {
            let sevenMmsDaysEarlier = dayjs(this.latestDate)
                .subtract(6, "day")
                .format("YYYY-MM-DD")
            this.fieldData.start_date.tempStartDate = sevenMmsDaysEarlier
            this.fieldData.end_date.tempEndDate = this.latestDate
        },
        clickedF1v() {
            let weekday = require("dayjs/plugin/weekday")
            dayjs.extend(weekday)
            let isProgramToDateSunday = dayjs(this.latestDate).weekday() == 6
            let monday = dayjs(this.latestDate)
                .weekday(isProgramToDateSunday ? 0 : -7)
                .format("YYYY-MM-DD")
            let sunday = dayjs(this.latestDate)
                .weekday(isProgramToDateSunday ? 6 : -1)
                .format("YYYY-MM-DD")
            this.fieldData.start_date.tempStartDate = monday
            this.fieldData.end_date.tempEndDate = sunday
        },
        popupIsShown() {
            document.addEventListener("keyup", this.onKeyUp)
            const query = this.$store.getters.getProgramsQuery
            this.fieldData.start_date.tempStartDate = query.start_date
            this.fieldData.end_date.tempEndDate = query.end_date
            this.fieldData.pub_date.tempSearchForPubDate = query.searchForPubDate
            this.fieldData.pub_date.tempPubStartDate = query.pub_start_date
            this.fieldData.pub_date.tempPubEndDate = query.pub_end_date
        },
        popupIsHidden() {
            document.removeEventListener("keyup", this.onKeyUp)
        },
        saveDate() {
            const query = this.$store.getters.getProgramsQuery
            query.start_date = this.fieldData.start_date.tempStartDate
            query.end_date = this.fieldData.end_date.tempEndDate
            query.searchForPubDate = this.fieldData.pub_date.tempSearchForPubDate
            query.pub_start_date = this.fieldData.pub_date.tempPubStartDate
            query.pub_end_date = this.fieldData.pub_date.tempPubEndDate
        },
        onKeyUp(event) {
            if (event.key === "Enter" && this.fieldData.showDateModal) {
                this.fieldData.showDateModal = false
                this.saveDate()
            }
        },
    },
}
</script>
<style scoped>
>>> .modal-content {
    width: 33rem;
}
.error {
    color: red;
    transition: color 0.3s;
}
</style>
