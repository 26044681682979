export const baseFields = {
    date: {
        start_date: {
            label: "Konsumtionsperiod från",
            errorMsg: "",
            type: "start_date_programs",
            identifier: "start_date",
            default: null,
            required: true,
            tempStartDate: "",
            hasError: false,
        },
        end_date: {
            label: "Konsumtionsperiod till",
            errorMsg: "",
            type: "end_date_programs",
            identifier: "end_date",
            default: null,
            required: true,
            tempEndDate: "",
            hasError: false,
        },
        pub_date: {
            identifier: "pub_date",
            tempSearchForPubDate: false,
            tempPubStartDate: "",
            tempPubEndDate: "",
            hasError: false,
        },
        showDateModal: false,
    },
    title: {
        label: "Titel",
        type: "text",
        identifier: "title",
        default: "",
        showTitleModal: false,
    },
    providers: {
        label: "Kanaler",
        type: "provider-picker-programs",
        identifier: "provider_keys",
        title: "Kanaler",
        sites: [],
        channels: [],
        default: [],
        showChannelModal: false,
    },
    genres: {
        label: "Genre",
        type: "genre-picker",
        identifier: "genres",
        config: {
            genres: [],
        },
        default: "",
        showGenreModal: false,
    },
    target_group: {
        label: "Målgrupp",
        identifier: "target_group",
        type: "target_group_programs",
        config: {},
        showTargetGroupModal: false,
    },
    show_all_content_with_traffic_in_period: {
        label: "Visa tomma svar",
        active_text: "Visar tomma svar",
        inactive_text: "Döljer tomma svar",
        tooltip_text: "Visa allt innehåll med tittande under perioden (oavsett valda målgrupper)",
        identifier: "show_all_content_with_traffic_in_period",
        type: "toggle-button",
    },
}
